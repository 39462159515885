/* highlight selected tags */
(function () {
  const tags = Array.prototype.slice.call(
    document.querySelectorAll(".js-tag"),
    0,
  );
  const checkboxes = Array.prototype.slice.call(
    document.querySelectorAll(".js-tag-checkbox"),
    0,
  );

  if (tags.length > 0) {
    checkboxes.map((item) => {
      const update = () => {
        if (item.checked) item.parentNode.classList.add("selected");
        else item.parentNode.classList.remove("selected");
      };
      item.addEventListener("change", update);
      update();
    });
  }
})();

/* tags list toggle mechanism */

function toggleExpand(trigger) {
  const tags = document.getElementById("js-tags-container");
  trigger.classList.toggle("expanded");
  if (trigger.dataset.togglePersistence) {
    if (trigger.classList.contains("expanded"))
      sessionStorage["tags-expanded"] = true;
    else delete sessionStorage["tags-expanded"];
  }
  tags.classList.toggle("expanded");
  if (tags.style.maxHeight) {
    tags.style.maxHeight = null;
  } else {
    tags.style.maxHeight = tags.height + "px";
    if (!tags.classList.contains("expanded")) {
      tags.style.maxHeight = null;
    }
  }
}

function expandTags() {
  const trigger = document.getElementById("js-tags-trigger");

  if (trigger) {
    trigger.addEventListener("click", () => {
      toggleExpand(trigger);
    });

    if (trigger.dataset.togglePersistence) {
      if (sessionStorage["tags-expanded"]) toggleExpand(trigger);
    } else {
      /* forget the state if we visit non-persistent toggler */
      delete sessionStorage["tags-expanded"];
    }
  }
}

expandTags();

(function dropdownMenu() {
  const dropdownTriggers = document.querySelectorAll(".js-dropdown-trigger");

  if (!dropdownTriggers) {
    return;
  }
  Array.from(dropdownTriggers).forEach((dropdownTrigger) =>
    dropdownTrigger.addEventListener("click", function () {
      this.parentNode.classList.toggle("js-dropdown-visible");
    }),
  );
})();
