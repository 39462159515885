import Cookies from "js-cookie";

const cookieAlertName = "zn_cookie_closed";

function closeCookieAlert() {
  Cookies.set(cookieAlertName, "true", { path: "/", expires: 365 });
  document.querySelector(".cookies").style.display = "none";
}

export function showCookieDialog() {
  if (Cookies.get(cookieAlertName) === undefined) {
    document.querySelector(".cookies").style.display = "block";
  }
}

export function bindCloseListener() {
  const button = document.getElementById("close-cookie-button");
  button.addEventListener("click", closeCookieAlert);
}
