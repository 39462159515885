import autocomplete from "autocomplete.js";

const inputSelector = "#autoComplete";

const getAutocompleteData = (query) => {
  return fetch(`/uzupełnij/?phrase=${query}`).then((source) => {
    return source.json();
  });
};

const autocompleteInstance = autocomplete(
  inputSelector,
  {
    hint: false, // dont do autocomplete in the text field
    minLength: 3,
  },
  [
    {
      source: (query, cb) => getAutocompleteData(query).then(cb),
      debounce: 400, // do the query this many miliseconds after last change,
      templates: {
        suggestion: autocomplete.escapeHighlightedString,
      },
    },
  ],
);

autocompleteInstance.on("autocomplete:selected", (event, suggestion) => {
  // on Edge we get multiple events, some with 'undefined' suggestion
  if (!suggestion) return;

  // set input value to selected suggestion
  autocompleteInstance.autocomplete.setVal(suggestion);
  // and submit search form
  event.target.form.submit();
});
