import "../scss/main.scss";

import "./autocomplete";
import "./glightbox";
import "./navbar";
import "./print";
import "./tags";
import { showCookieDialog, bindCloseListener } from "./cookie_info";
import lightCandle from "./lightCandle";

showCookieDialog();
bindCloseListener();
lightCandle();
