import Cookies from "js-cookie";

export default function lightCandle() {
  const candleWrap = document.querySelector(".light-candle-wrap");
  if (!candleWrap) {
    return;
  }
  const btn = candleWrap.querySelector(".button");
  if (!btn) {
    return;
  }
  btn.addEventListener("click", function () {
    fetch(btn.dataset.url, {
      method: "PATCH",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(() => {
        candleWrap.classList.add("is-candle-active");
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error({ msg: "ERROR LIGHTING CANDLE", error });
      });
  });
}
